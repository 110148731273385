<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat dense>
        <v-toolbar-title>Sales Employees</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="primary" text to="/new-sales-employee">
          <v-icon left dark>mdi-plus</v-icon>New Sales Employee
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-btn @click="defaultModal" color="primary" text
              ><v-icon left>mdi-check</v-icon>Set Default</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-text-field dense v-model="search" label="Search"></v-text-field>
          <v-col cols="12" sm="12" md="12">
            <v-data-table
              @item-selected="clickedItem"
              v-model="selectedEmployees"
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="employees"
              :search="search"
              item-key="id"
              show-select
              :single-select="singleSelect"
            >
              <template v-slot:item.SlpName="{ item }">
                <v-btn color="primary" text :to="`/sales-employees/${item.id}`">{{
                  item.SlpName
                }}</v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <!-- start of dialog -->
        <v-dialog v-model="dialog" max-width="500px">
          <v-card>
            <v-toolbar color="accent" dark>
              <v-toolbar-title>Set Default Sales Employee</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click.native="dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>

            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    dense
                    outlined
                    label="Name"
                    filled
                    readonly
                    v-model="selectedEmployee.SlpName"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-btn color="accent" @click="save" :loading="loader">Ok</v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- end of dialog -->

        <!-- snackbar -->
        <snackbar ref="snackbar"></snackbar>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    search: "",
    employees: [],
    loader: false,
    dialog: false,
    singleSelect: true,
    selectedEmployees: [],
    selectedEmployee: {},
    headers: [
      { text: "id", value: "id" },
      { text: "Slp Code", value: "SlpCode" },
      { text: "Name", value: "SlpName" },
      { text: "Remarks", value: "Memo" },
      { text: "Tier", value: "tier.name" },
    ],
  }),
  methods: {
    clickedItem($event) {
      this.selectedEmployee = $event.item;
    },
    defaultModal() {
      if (this.selectedEmployee.id === undefined) {
        this.$refs.snackbar.show("Please select sales employee", "red");
      } else {
        this.dialog = true;
      }
    },
    save() {
      const data = {
        DfltSlp: SlpId,
      };
      const SlpId = this.selectedEmployee.id;
      const url = `/employee/set_default/${SlpId}`;
      const self = this;
      self.loader = true;
      this.$store
        .dispatch("put", { url, data })
        .then(() => {
          location.reload();
        })
        .catch((err) => {
          console.log(err, "err");
          // this.$refs.snackbar.show(err, "red");
        });
    },
    getEmployees() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/employee`)
        .then((res) => {
          self.employees = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    generalSettings() {
      const self = this;
      this.$store
        .dispatch("get", `/general_settings`)
        .then((res) => {
          // get sales employee
          if (res.ResponseData.DfltSlp !== null) {
            const id = res.ResponseData.DfltSlp;
            const employeeData = self.employees.find((record) => {
              return record.id == id;
            });
            self.selectedEmployees.push(employeeData);
          }
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getEmployees();
    this.generalSettings();
  },
};
</script>
